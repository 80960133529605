<template>
    <b-card no-body>
        <b-card-header>
            <h3>IVMR</h3>            
            <b-col class="text-right">
                <b-button variant="primary" @click="exportPDF">
                    <feather-icon
                        icon="DownloadIcon"
                        class="mr-50"
                    />Download</b-button
                >
            </b-col>
        </b-card-header>
        <b-card-body>
            <div>
                <b-row>
                    <b-col cols="4">
                        <!-- sorting  -->
                        <b-form-group
                            label-size="sm"
                            label-align-sm="left"
                            label-for="sortBySelect"
                            class="mb-md-0"
                        >
                            <b-form-group label="Date Range">
                                <flat-pickr
                                    v-model="rangeDate"
                                    @input="onChangeDate(rangeDate)"
                                    class="form-control"
                                    :config="Config"
                                />
                            </b-form-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="4">
                        <b-form-group label="Domicile">
                            <v-select
            label="label"
            placeholder="Select"
            v-model="selectedDomicile"
            :options="domiciles"
            @input="getVehicles()"
          />
                            </b-form-group>
                    </b-col>

                    <b-col cols="4">
                        <b-form-group label="Vehicle">
                            <v-select
                                multiple
                                v-model="selected"
                                :dir="
                                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                label="label"
                                :options="option"
                                @input="onChangeVehicle()"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <div class="d-flex justify-content-center">
                <div style="width: 100%;" >
                    <pdfexport ref="contentToExport" :fileName="pdfFileName" forcePageBreak=".page-break" :margin="'0.5cm'" :paper-size="'A4'" :keepTogether="'.prevent-split'">
                        <b-overlay
                            :show="show"
                            rounded="sm"
                            >
                            <div v-for="(n, i) in data.length" :key="i">
                                <ContentToPrint :data="data[i]" :startDate="startDate" :endDate="endDate" />                        
                                <div class="page-break"></div>
                            </div>
                        </b-overlay>
                    </pdfexport>
                </div>
            </div>
        </b-card-body>       
    </b-card>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButtonGroup,
    BButton,
    BFormGroup,
    BOverlay
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from "axios";
import { serverUri } from "@/config";
import ContentToPrint from "./ContentToPrint";
import { PDFExport } from "@progress/kendo-vue-pdf";
import moment from 'moment';
import drivers from '@/store/drivers';
import { getUserData } from "@/auth/utils";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BButtonGroup,
        BButton,
        BFormGroup,
        BOverlay,
        vSelect,
        flatPickr,
        ContentToPrint,
        pdfexport: PDFExport,
    },
    data() {
        return {
            pdfFileName : getUserData().company_name+'_IVMR',
            selectedDomicile: "",
            domiciles : [],
            selected: [],
            selectedVehicleValues: [],
            option: [],
            rangeDate: null,
            startDate: null,
            endDate: null,
            data: [],
            temp: [],
            show: false,
            Config:{ 
                mode: 'range',
                wrap: true, // set wrap to true only when using 'input-group'
                altFormat: 'm/d/Y',
                altInput: true,
                dateFormat: 'm/d/Y',
            }
        }
    },
    created() {
     

        axios
      .post(`${serverUri}/schedule/getdomicile`, {
        company: getUserData().company_id,
      })
      .then((res) => {
        const Response = res.data.filter((item) => item.domicile?.length > 0);

        Response.map((item) => {
          this.domiciles.push({ title: item.domicile, label: item.domicile });
        });
      });
        
    },
    methods: {
        getVehicles()
        {
            this.option = [];
            axios.post(`${serverUri}/fleet/getFleetByDomicile`,
            {
                domicile : this.selectedDomicile !=undefined ? this.selectedDomicile.title : this.selectedDomicile,
                company: getUserData().company_id,
                status : 'Active'
            }).then((res) => {
                res.data.map((item) => 
                {
                    this.option.push({ title: item.tractor_id, label: item.tractor_id });
                })
            });

        },
        exportPDF: function() {
            this.$refs.contentToExport.save();
        },
        onLoad() {

            this.pdfFileName = getUserData().company_name+'_IVMR_'+this.endDate;

            if (this.rangeDate && this.selected.length) {
                this.show = true;
                axios.post(`${serverUri}/mileage/report`, {
                    start: moment(this.startDate).format('YYYY-MM-DD'),
                    end: moment(this.endDate).format('YYYY-MM-DD'),
                    vehicle: this.selectedVehicleValues
                }).then((res) => {
                    this.data = [];
                    this.temp = [];

                    this.recursion(0, 0, res.data.result);

                    this.data = this.getDataBy2hr(this.data);

                    this.data = this.addSomeHeaders(this.data, res.data);
                    
                    this.show = false;
                })
            }
        },
        getDataBy2hr(data) {
            let prevItem = {};
            let filteredData = [];
            data = data.map((item, index) => {
                prevItem = item.data[0];                        
                filteredData = [prevItem];
                for (let i = 1; i < item.data.length; i ++) {
                    if (moment(prevItem.position_time).format('YYYY-MM-DD') == moment(item.data[i].position_time).format('YYYY-MM-DD') ) {
                        if ( (parseInt(moment(prevItem.position_time).format('HH')) + 2) == moment(item.data[i].position_time).format('HH') ) {
                            filteredData.push(item.data[i]);
                            prevItem = item.data[i];
                        }
                    } else {
                        prevItem = item.data[i];
                    }
                }

                console.log(filteredData)

                return {
                    ...item,
                    data: filteredData
                }
            })

            return data;
        },
        addSomeHeaders(data, respData) {
            data = data.map((item, index) => {
                var sum = 0;
                for (let i = 0; i < item.data.length; i ++) {
                    if (i != 0 && item.data[i].odometer != item.data[i-1].odometer && Math.round(item.data[i].odometer - item.data[i-1].odometer) > 0) {
                        sum += Math.round(item.data[i].odometer - item.data[i-1].odometer)
                    }
                }
                
                return {
                    ...item,
                    totalMiles: sum,
                    driver: respData.drivers[index].driver_name,
                    domicile: respData.domiciles[index].domicile
                }
            })

            return data;
        },
        onChangeDate(date) {
            if (date.split("to")) {
                this.startDate = date.split("to")[0];
                this.endDate = date.split("to")[1];
            } else {
                this.startDate = date;
            }
            
            this.onLoad();
        },

        onChangeVehicle() {
            this.selectedVehicleValues = [];
            this.selected.map((item) => {
                this.selectedVehicleValues.push(item.title);
            });

            this.onLoad();
        },

        getYearMonthFormat(date) {
            return moment(date).format('YYYY-MM');
        },

        recursion(idxOfVehicle, idxOfData, datas) {

            if (idxOfVehicle >= this.selectedVehicleValues.length || !datas.length) {
                return ;
            } else {
                for( ; idxOfData < datas.length; idxOfData ++) {
                    if (datas[idxOfData-1] && this.getYearMonthFormat(datas[idxOfData-1].position_time) == this.getYearMonthFormat(datas[idxOfData].position_time)) {
                        if (this.selectedVehicleValues[idxOfVehicle] == datas[idxOfData-1].equipment_id) {
                            this.temp.push(datas[idxOfData-1]);
                            // if (!datas[idxOfData+1] || 
                            //     (datas[idxOfData+1] && this.selectedVehicleValues[idxOfVehicle] != datas[idxOfData+1].equipment_id)) {
                            //     this.temp.push(datas[idxOfData]);
                            // }
                        }
                    } else {
                        break;
                    }
                }

                if (this.temp.length) {
                    this.data.push({vehicle: this.selectedVehicleValues[idxOfVehicle], data: this.temp});
                    this.temp = [];
                }

                if (idxOfData == datas.length) {
                    idxOfVehicle ++;
                    idxOfData = 0;
                } else {
                    idxOfData ++;
                }
                this.recursion(idxOfVehicle, idxOfData, datas);
            }
        },

    }
}

</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>