<template>
  <div>
    <div>
        <div>
            <h5 >Vehicle Position History List - Ground Force Trucking</h5>
        </div>
        <div style="font-size: 10px;" >
            <div class="d-flex align-item-center justify-content-between">
                <div style="margin-bottom: 5px;">
                    Vehicle: <span>{{ data.vehicle }}</span><br>
                    From Date: <span>{{ startDate }}</span><br>
                    To Date: <span>{{ endDate ? endDate : startDate }}</span><br>
                </div>
                <div style="margin-bottom: 5px;">
                    Domicile Location: <span>{{ data.domicile }}</span><br>
                    Driver(s): <span>{{ data.driver }}</span><br>
                    Total Miles: <span>{{ data.totalMiles }}</span><br>
                </div>
            </div>
            <!-- <span v-if="data[i-1] && (getYearMonthFormat(data[i-1].event_time != getYearMonthFormat(data[i].event_time)))">
                <div class="page-break"/>
            </span> -->
            <table>
                <tr>
                    <th width="200px;">Position Time</th>
                    <th>Location</th>
                    <th>Odometer</th>
                    <th>Distance Travelled</th>
                    <th>Date</th>
                    <th>State</th>
                </tr>
                <tr v-for="(n, i) in data.data.length" :key="i">
                    <td width="200px;" v-if="i != 0 && data.data[i].odometer != data.data[i-1].odometer && Math.round(data.data[i].odometer - data.data[i-1].odometer) > 0">
                        {{ getFormattedDateTime(data.data[i].position_time) }}
                    </td>
                    <td v-if="i != 0 && data.data[i].odometer != data.data[i-1].odometer && Math.round(data.data[i].odometer - data.data[i-1].odometer) > 0">
                        {{ data.data[i].location }}
                    </td>
                    <td v-if="i != 0 && data.data[i].odometer != data.data[i-1].odometer && Math.round(data.data[i].odometer - data.data[i-1].odometer) > 0">
                        {{ Math.round(data.data[i].odometer).toLocaleString() }}
                    </td>
                    <td v-if="i != 0 && data.data[i].odometer != data.data[i-1].odometer && Math.round(data.data[i].odometer - data.data[i-1].odometer) > 0">
                        {{ Math.round(data.data[i].odometer - data.data[i-1].odometer).toLocaleString() }}
                    </td>
                    <td v-if="i != 0 && data.data[i].odometer != data.data[i-1].odometer && Math.round(data.data[i].odometer - data.data[i-1].odometer) > 0">
                        {{ getFormattedDate(data.data[i].position_time) }}
                    </td>
                    <td v-if="i != 0 && data.data[i].odometer != data.data[i-1].odometer && Math.round(data.data[i].odometer - data.data[i-1].odometer) > 0">
                        {{ data.data[i].state }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
    
export default {
    props: {
        data: Object,
        startDate: String,
        endDate: String,
    },
    methods: {
        getFormattedDate(date) {
            return moment(date).format("MM/DD/YYYY");
        },
        getFormattedDateTime(date) {
            return moment(date).format("MM/DD/YYYY h:mm:ss a");
        },        
        getYearMonthFormat(date) {
            return moment(date).format('YYYY-MM');
        },
    },
}

</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
}

th, td {
  text-align: center;
  padding: 1px;
  border: 1px solid #3b4253;
}

th {
  background-color: #4472c4;
  color: white;
}

h5 {
    text-align: center;
}

span {
    font-weight: bold;
}
</style>